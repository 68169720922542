<template>
  <div id="faq-view">
    <section class="bg-light  ">
      <main>
        <div class="container py-5">
          <div><h1>FAQ</h1></div>
          <ul>
            <li class="mb-5 " v-for="faq in faqs">
              <div class="row">
                <div no-body class="mb-1 col-md-12">
                  <div v-b-toggle="faq.id.toString()" class="col-md-12 ">
                    <a href="#"
                      ><h3 class="">{{ faq.question }}</h3></a
                    >
                  </div>
                  <div class="row">
                    <div class="ml-5">
                      <b-collapse
                        :id="faq.id.toString()"
                        accordion="my-accordion"
                        role="tabpanel"
                      >
                        <b-card-body>
                          <b-card-text v-html="faq.answer"></b-card-text>
                        </b-card-body>
                      </b-collapse>
                    </div>
                  </div>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </main>
    </section>
  </div>
</template>
<script>
export default {
  name: "FAQView",
  metaInfo: {
    title: "FAQ",
    meta: [
      {
        name: "description",
        content:
          "Explore our Frequently Asked Questions (FAQ) to find answers to common inquiries. Get quick insights about our services and offerings!",
      },
      {
        name: "keywords",
        content:
          "FAQ, Frequently Asked Questions, Customer support, Help center, Common inquiries, Service information, Knowledge base, User assistance, Online help, FAQ section",
      },
    ],
  },
  data() {
    return {
      loader: false,
      errors: [],
      faqs: [],
      text:
        "flkasdgfaijsfba sfuasyfasyfiasf asyfasifauysf aifgasyfdg afg uasydfgasy fgasidgfausy dfgysgd fysgfsyf gsi f",
    };
  },
  mounted() {
    this.getPageContent();
  },
  methods: {
    getPageContent() {
      var segment_str = window.location.pathname;
      var segment_array = segment_str.split("/");
      var last_segment = segment_array.pop();
      this.loader = true;
      var method_type = "POST";
      var fetch_url = process.env.VUE_APP_URL + "customer/faqs/getallFAQs";
      fetch(fetch_url, {
        method: method_type,
        headers: {
          "content-type": "application/json",
          "X-Requested-With": "XMLHttpRequest",
        },
        body: JSON.stringify({
          slug: last_segment,
        }),
      })
        .then((res) => res.json())
        .then((data) => {
          if (data.faqs != null) {
            this.faqs = data.faqs;
          }
          this.loader = false;
        });
    },
  },
};
</script>
